import React from 'react'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'
import InupsWhiteLogo from '../assets/image/logo/inups-white.png'
import dayjs from 'dayjs'
import {Checkbox} from '../components'
import {Storage} from '../const'

const Container = styled(Styles.Container)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #030c38;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  padding: 20px;
  gap: 20px;

  pre {
    margin: 0;
    color: white;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 400;
  }
`

const Sign = styled.div`
  color: white;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 600;
  text-align: right;
  padding: 20px;
  width: 100%;
`

const OpenPopup = styled.div`
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  height: 30px;
  align-items: center;
  display: flex;
  padding: 0 20px;
`

function Notice() {
  const [checked, setChecked] = React.useState(false)
  return (
    <Container>
      <img src={InupsWhiteLogo} width="78px" />
      <pre>
        {`
      
        주식회사 인업스의 합병 사실을 공지드립니다.

        주식회사 인업스(이하 "인업스")와 주식회사 에너닷(이하 "에너닷")은 2024년 12월 20일 합병계약을 체결한 바, 
        2025년 1월 24일을 합병기일로 하여 인업스는 에너닷으로 흡수합병(이하 "본건 합병")되어 
        에너닷이 인업스의 모든 자산,부채,권리 및 의무를 승계하고 인업스는 해산하게 됩니다. 
        이에 인업스가 보유하고 있던 고객 및 거래 상대방의 개인정보 등 일체의 개인정보는 에너닷에 이전될 것입니다. 
        에너닷은 이전되는 개인정보에 대하여 개인정보보호법을 준수하여 안전성 확보조치를 할 것이며, 이전 당시의 본래 목적으로만 해당 정보를 처리할 것입니다. 
        이러한 개인정보 이전에 관하여 이의가 있거나 이전되는 것을 원하지 않을 경우 고객센터(02-1833-3669)로 문의하여 
        개인정보 수집 및 이용에 대한 동의를 철회할 수 있습니다. 
        다만, 개인정보 이전을 원하지 않는 경우, 본건 합병 이후 기존에 인업스가 제공하던 서비스의 이용이 제한될 수 있으며, 
        개인정보 이전에 동의하지 않으시는 경우에도 에너닷은 관련 법령상 의무를 준수하기 위하여 일부 정보를 일정 기간 보유할 수 있습니다.

        개인정보를 이전 받게 되는 본건 합병의 존속회사의 정보는 다음과 같습니다.
        ·         상호명 : 주식회사 에너닷
        ·         주소 :  서울특별시 강남구 봉은사로 179 9층
        ·         전화번호 : 1644-4073
      `}
      </pre>
      <Sign>
        2024년 12월 24일
        <br />
        주식회사 인업스 대표이사 강인철
      </Sign>
      <OpenPopup>
        <Checkbox
          id="isOpenPopup"
          checked={checked}
          text="1일동안 표시안하기"
          onChange={(e) => {
            const c = e.target.checked
            if (c) {
              Storage.set('popup', dayjs().add(1, 'day').format('YYYY-MM-DD'))
            } else {
              Storage.remove('popup')
            }
            setChecked(e.target.checked)
            window.close()
          }}
        />
      </OpenPopup>
    </Container>
  )
}

export default Notice
