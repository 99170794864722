import React from 'react'
import styled, {css} from 'styled-components'
import Styles from '../assets/theme/styles'
import Colors from '../assets/theme/colors'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import {convertViewPointPx, openPopup} from '../const/common'
import {Storage} from '../const'

import WebBanner from '../assets/image/web/home/Banner.png'
import MobileBanner from '../assets/image/mobile/home/Banner-mobile.png'
import BannerVideo from '../assets/video/banner.mov'

import PointDiagram from '../assets/image/web/home/diagram1.png'
import GasAllianceDiagram from '../assets/image/web/home/diagram2.png'
import MobilePointDiagram from '../assets/image/mobile/home/diagram1-mobile.png'
import MobileGasAllianceDiagram from '../assets/image/mobile/home/diagram2-mobile.png'
import TotalServiceBg from '../assets/image/web/home/totalService.png'
import EnergySolutionBg from '../assets/image/web/home/energySolution.png'
import SmartMonitoringBg from '../assets/image/web/home/smartMonitoring.png'
import ConsultingBg from '../assets/image/web/home/consulting.png'
import MobileTotalServiceBg from '../assets/image/mobile/home/totalService-mobile.png'
import MobileEnergySolutionBg from '../assets/image/mobile/home/energySolution-mobile.png'
import MobileSmartMonitoringBg from '../assets/image/mobile/home/smartMonitoring-mobile.png'
import MobileConsultingBg from '../assets/image/mobile/home/consulting-mobile.png'
import GasAllianceSlider from '../components/GasAllianceSlider'

dayjs.extend(isSameOrAfter)

const Contents = styled(Styles.Contents)`
  background: #030c38;
`

const Container = styled(Styles.Container)`
  ${(props) =>
    props.Banner &&
    css`
      height: 100vh;
      position: relative;
      overflow: hidden;
      & video {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        background-position: center center;
        background-size: cover;
        filter: brightness(0.4);
      }
    `}
  @media all and (min-width: 641px) {
    ${(props) =>
      props.Banner &&
      css`
        background-image: url(${WebBanner});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      `}
    ${(props) =>
      props.Slogan &&
      css`
        background-color: ${Colors.navy};
        padding-top: ${convertViewPointPx(220)}px;
      `}
    ${(props) =>
      props.Service &&
      css`
        background-color: ${Colors.navy};
        padding-top: ${convertViewPointPx(100)}px;
        padding-bottom: ${convertViewPointPx(220)}px;
      `}
    ${(props) =>
      props.JoinUs &&
      css`
        background-color: #152370;
        padding: ${convertViewPointPx(64)}px;
        & > div {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      `}
  }

  @media all and (max-width: 640px) {
    ${(props) =>
      props.Banner &&
      css`
        background-image: url(${MobileBanner});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        & video {
          transform: translate(-50%);
        }
      `}
    ${(props) =>
      props.Slogan &&
      css`
        background-color: ${Colors.navy};
        padding-top: ${convertViewPointPx(100)}px;
      `}
    ${(props) =>
      props.Service &&
      css`
        background-color: ${Colors.navy};
        padding-top: ${convertViewPointPx(150)}px;
        padding-bottom: ${convertViewPointPx(100)}px;
      `}
    ${(props) =>
      props.JoinUs &&
      css`
        padding: ${convertViewPointPx(50)}px;
        background-color: #152370;
        & > div {
          display: flex;
          align-items: center;
        }
      `}
  }
`

const Paper = styled(Styles.Paper)`
  ${(props) =>
    props.Service &&
    css`
      @media all and (min-width: 641px) {
        width: 85%;
      }
    `}
`

const Banner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Title = styled.div`
  font-family: 'Pretendard-Bold';
  color: #fff;
  word-break: keep-all;
  text-align: center;

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(56)}px;
    max-width: ${convertViewPointPx(717)}px;
    &:nth-child(2) {
      margin-top: ${convertViewPointPx(10)}px;
    }
  }

  @media all and (max-width: 640px) {
    font-size: ${convertViewPointPx(25)}px;
    line-height: ${convertViewPointPx(34)}px;
  }
`

const Subtitle = styled.div`
  font-family: 'Pretendard-Medium';
  color: #fff;
  word-break: keep-all;
  text-align: center;
  margin-top: ${convertViewPointPx(16)}px;

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(32)}px;
  }

  @media all and (max-width: 640px) {
    font-size: ${convertViewPointPx(16)}px;
  }
`

const Text = styled.div`
  font-family: 'Pretendard-Bold';
  color: ${(props) => (props.color ? props.color : Colors.black)};
  text-align: center;
  word-break: keep-all;

  @media all and (min-width: 641px) {
    display: ${(props) => ((!props.web && !props.mobile) || props.web ? 'block' : 'none')};
    font-size: ${convertViewPointPx(48)}px;
    line-height: ${convertViewPointPx(67)}px;
  }

  @media all and (max-width: 640px) {
    display: ${(props) => ((!props.web && !props.mobile) || props.mobile ? 'block' : 'none')};
    font-size: ${(props) =>
      props.fontSize ? `${convertViewPointPx(props.fontSize)}px` : `${convertViewPointPx(18)}px`};
    line-height: ${convertViewPointPx(27)}px;
  }

  @media all and (max-width: 480px) {
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
  }
`

const Subtext = styled.div`
  font-family: 'Pretendard-Medium';
  color: ${(props) => (props.color ? props.color : Colors.black)};
  text-align: center;
  word-break: keep-all;

  @media all and (min-width: 641px) {
    display: ${(props) => ((!props.web && !props.mobile) || props.web ? 'block' : 'none')};
    font-size: ${convertViewPointPx(16)}px;
    line-height: ${convertViewPointPx(24)}px;
  }

  @media all and (max-width: 640px) {
    display: ${(props) => ((!props.web && !props.mobile) || props.mobile ? 'block' : 'none')};
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '12px')};
    line-height: ${convertViewPointPx(16)}px;
  }

  @media all and (max-width: 480px) {
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
  }
`

const PointDiagramWrap = styled.div`
  @media all and (min-width: 641px) {
    padding-top: ${convertViewPointPx(100)}px;
    max-width: ${convertViewPointPx(896)}px;
    max-height: ${convertViewPointPx(308)}px;
  }

  @media all and (max-width: 640px) {
    padding-top: ${convertViewPointPx(64)}px;
  }
`

const GasAllianceDiagramWrap = styled.div`
  @media all and (min-width: 641px) {
    padding-top: 223px;
    max-width: ${convertViewPointPx(980)}px;
    max-height: ${convertViewPointPx(642)}px;
  }

  @media all and (max-width: 640px) {
    padding-top: ${convertViewPointPx(151)}px;
    padding-bottom: ${convertViewPointPx(24)}px;
  }
`

const GasAllianceDiagramMobileWrap = styled.div`
  @media all and (min-width: 641px) {
    display: none;
  }

  @media all and (max-width: 640px) {
    display: block;
  }
`

const Diagram = styled(Styles.Diagram)``

const CardWrap = styled.div`
  display: flex;

  @media all and (min-width: 641px) {
    padding-top: ${convertViewPointPx(100)}px;
    flex-direction: row;
  }

  @media all and (max-width: 640px) {
    padding-top: ${convertViewPointPx(30)}px;
    flex-direction: column;
  }
`

const Card = styled(Styles.Card)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    text-align: left;
  }

  @media all and (min-width: 641px) {
    max-width: ${convertViewPointPx(380)}px;
    height: ${convertViewPointPx(490)}px;
    margin-right: ${convertViewPointPx(27)}px;
    padding: ${convertViewPointPx(36)}px;

    &:last-child {
      margin-right: 0;
    }

    & > div:first-child {
      font-family: 'Pretendard-Bold';
      color: #fff;
      line-height: ${convertViewPointPx(33.6)}px;
    }
    & > div:last-child {
      font-family: 'Pretendard-Regular';
      color: #fff;
      line-height: ${convertViewPointPx(24)}px;
    }

    ${(props) =>
      props.TotalService &&
      css`
        background-image: url(${TotalServiceBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
      `}
    ${(props) =>
      props.Consulting &&
      css`
        background-image: url(${ConsultingBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
      `}
    ${(props) =>
      props.EnergySolution &&
      css`
        background-image: url(${EnergySolutionBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
      `}
    ${(props) =>
      props.SmartMonitoring &&
      css`
        background-image: url(${SmartMonitoringBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
      `}
  }

  @media all and (max-width: 640px) {
    width: 100%;
    min-width: ${convertViewPointPx(327)}px;
    min-height: ${convertViewPointPx(245)}px;
    margin-bottom: ${convertViewPointPx(23)}px;
    padding: ${convertViewPointPx(30)}px;

    &:last-child {
      margin-right: 0;
    }

    ${(props) =>
      props.TotalService &&
      css`
        background-image: url(${MobileTotalServiceBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
      `}
    ${(props) =>
      props.Consulting &&
      css`
        background-image: url(${MobileConsultingBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
      `}
    ${(props) =>
      props.EnergySolution &&
      css`
        background-image: url(${MobileEnergySolutionBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
      `}
    ${(props) =>
      props.SmartMonitoring &&
      css`
        background-image: url(${MobileSmartMonitoringBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
      `}
  }
`

const Name = styled.div`
  color: white;
  font-family: 'Pretendard-Bold';

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(28)}px;
    padding-bottom: ${convertViewPointPx(34)}px;
  }

  @media all and (max-width: 640px) {
    font-size: ${convertViewPointPx(20)}px;
    padding-bottom: ${convertViewPointPx(8)}px;
  }
`

const Detail = styled.div`
  text-align: center;
  color: white;
  font-family: 'Pretendard-Medium';

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(18)}px;
    line-height: ${convertViewPointPx(28.8)}px;
  }

  @media all and (max-width: 640px) {
    font-size: ${convertViewPointPx(14)}px;
    line-height: ${convertViewPointPx(19)}px;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;

  color: white;
  border: 1px solid #fff;

  @media all and (min-width: 641px) {
    font-family: 'Pretendard-Bold';
    font-size: ${convertViewPointPx(24)}px;
    width: ${convertViewPointPx(363)}px;
    height: ${convertViewPointPx(77)}px;
    border-radius: ${convertViewPointPx(48)}px;
    margin-top: ${convertViewPointPx(48)}px;
  }

  @media all and (max-width: 640px) {
    font-family: 'Pretendard-Medium';
    width: ${convertViewPointPx(180)}px;
    height: ${convertViewPointPx(43)}px;
    border-radius: ${convertViewPointPx(48)}px;
    font-size: ${convertViewPointPx(14)}px;
    margin-top: ${convertViewPointPx(24)}px;
  }
`

function Home() {
  React.useEffect(() => {
    const isOpenPopup = Storage.get('popup')
    if (!isOpenPopup || dayjs().isSameOrAfter(dayjs(isOpenPopup))) {
      openPopup({
        url: '/notice',
        width: 970,
        height: 700,
      })
    }
  }, [])

  return (
    <Contents>
      <Container Banner>
        <Paper>
          <video autoPlay="autoplay" loop muted playsInline>
            <source src={BannerVideo} type="video/webm" />
          </video>
          <Banner>
            <TitleWrap>
              <Title>인업스는 당신이 생산한 에너지를</Title>
              <Title>관리하고 가치를 높입니다</Title>
              <Subtitle>Integrates your Power Sources</Subtitle>
            </TitleWrap>
          </Banner>
        </Paper>
      </Container>
      <Container id="contents" Slogan>
        <Paper align="center">
          <Text color="white">에너지 프로슈머 시대에 에너지를 쉽고 편리하게</Text>
          <Text color="white">사용할 수 있도록, 고객과 에너지를 연결하는</Text>
          <Text color="white">종합 에너지플랫폼 서비스를 제공합니다</Text>
          <PointDiagramWrap>
            <Diagram src={PointDiagram} width="100%" height="100%" web />
            <Diagram src={MobilePointDiagram} width="168px" height="100%" mobile />
          </PointDiagramWrap>
          <GasAllianceDiagramWrap>
            <Diagram src={GasAllianceDiagram} width="100%" height="100%" web />
            <Diagram src={MobileGasAllianceDiagram} width="222px" height="100%" mobile />
          </GasAllianceDiagramWrap>
          <GasAllianceDiagramMobileWrap>
            <Text color="white">인업스는 도시가스 광역 10개사의</Text>
            <Text color="white">통합에너지 전문 운영기업으로</Text>
            <Text color="white">발돋움하고 있습니다</Text>
            <Subtext color="rgba(255, 255, 255, 0.8)" style={{paddingTop: 16}}>
              미래 전력산업과 에너지 신사업 패러다임 전환 추진을 위해
            </Subtext>
            <Subtext color="rgba(255, 255, 255, 0.8)">가스얼라이언스와 인업스 설립</Subtext>
            <GasAllianceSlider />
          </GasAllianceDiagramMobileWrap>
        </Paper>
      </Container>
      <Container Service>
        <Paper Service>
          <Text web color="white">
            사람과 에너지를 연결하여<br></br>새로운 에너지 시장의 패러다임을 열어갑니다
          </Text>
          <Text mobile fontSize={18} color="white">
            사람과 에너지를 연결하여<br></br>새로운 에너지 시장의 패러다임을 열어갑니다
          </Text>
          <CardWrap style={{justifyContent: 'center'}}>
            <Card TotalService>
              <Name>토탈 에너지 서비스</Name>
              <Detail>
                수요관리(DR), 전력중개사업, 모니터링 서비스,
                <br />
                원격감시제어
              </Detail>
            </Card>
            <Card Consulting>
              <Name>컨설팅</Name>
              <Detail>최적의 부하분석을 통한 최고의 수익 보장</Detail>
            </Card>
            <Card EnergySolution>
              <Name>에너지 솔루션</Name>
              <Detail>고객 유형별 에너지솔루션 제공</Detail>
            </Card>
            <Card SmartMonitoring>
              <Name>스마트 모니터링</Name>
              <Detail>모바일, 웹 기반의 전력량 모니터링 시스템</Detail>
            </Card>
          </CardWrap>
        </Paper>
      </Container>
      <Container JoinUs>
        <Paper>
          <ButtonWrap>
            <Text color="white">지금, 인업스와 함께하세요</Text>
            <Button
              onClick={() => {
                window.location.href = 'mailto:support@inups.kr'
              }}>
              서비스 문의하기
            </Button>
          </ButtonWrap>
        </Paper>
      </Container>
    </Contents>
  )
}

export default Home
